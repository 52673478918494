import * as React from 'react'
import { Loader } from '../components/Loader'
import { Page } from '../components/Page'
import { ToucanPage } from '../components/ToucanPage'
import { useCoreData } from '../utils/useCoreData'

const NotFoundPage = () => {
  return (
    <Page>
      <Content />
    </Page>
  )
}

function Content () {
  const {
    api: {
      data: { miscellaneous }
    }
  } = useCoreData()

  if (!miscellaneous) return <Loader />

  const { page_not_found } = miscellaneous

  return <ToucanPage small>{page_not_found}</ToucanPage>
}

export default NotFoundPage
